import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView"
import { DownloadItem } from "../components/Downloads/downloads"

const EsgOffenlegungen = ({
  data: { content },
  pageContext: { locale },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <SEO
        title={"ESG Offenlegungen"}
        description={content.data.seo_esg_beschreibung.text}
        keywords={
          content.data.seo_esg_keywords.text
            ? content.data.seo_esg_keywords.text.split(", ")
            : [`ESG Offenlegungen`]
        }
      />
      <DetailView img={content.data.esg_bild}>
        <section id="dabei-sein">
          <div className="container">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: content.data.esg.html,
              }}
            />
          </div>
          {content.data.esg_file && (
            <div className="downloads">
              <DownloadItem
                publication={{
                  node: {
                    id: "esg",
                    data: {
                      title: {
                        text:
                          "ESG Offenlegungen gemäß Offenlegungs-Verordnung (EU) 2019/2088",
                      },
                      file: {
                        name: content.data.esg_file.name,
                        url: content.data.esg_file.url,
                      },
                    },
                  },
                }}
              />
            </div>
          )}
        </section>
      </DetailView>
    </>
  )
}

export default EsgOffenlegungen

EsgOffenlegungen.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query EsgOffenlegungenQuery($locale: String!) {
    content: prismicHomepage(lang: { eq: $locale }) {
      data {
        esg {
          html
        }
        seo_esg_beschreibung {
          text
        }
        seo_esg_keywords {
          text
        }
        esg_file {
          url
          name
        }
      }
    }
  }
`
